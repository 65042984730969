//layout .l-{className}
.l- {
  &form {
    &-field {
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 100%;
      height: calc(1.5rem + 0.75rem + 2px);
      padding: $spacing-025 $spacing-075;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $gray-700;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid $gray-500;
      overflow: hidden;
      transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      &::placeholder {
        color: $gray-500;
        font-weight: 700;
      }
      &:focus {
        box-shadow: 0 0 0 4px rgba($gray-500, 0.5);
        outline: none;
      }
      &:disabled,
      &[readonly] {
        background-color: $gray-300;
      }
      // 驗證失敗時
      &.js-invalid {
        border-color: $danger;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        &:focus {
          box-shadow: 0 0 0 4px rgba($danger, 0.5);
        }
      }
      // 驗證成功時
      &.js-valid {
        border-color: $success;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        &:focus {
          box-shadow: 0 0 0 4px rgba($success, 0.5);
        }
      }
    }
    &-select {
      appearance: none;
      // ie10清除預設箭頭
      &::-ms-expand {
        display: none;
      }
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: 16px 12px;
      &.js-invalid {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-position: right 0.75rem center, center right 1.75rem;
        background-size: 16px 12px,
          calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
      &.js-valid {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-position: right 0.75rem center, center right 1.75rem;
        background-size: 16px 12px,
          calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
    }
    &-note {
      &--helper {
        color: $gray-500;
        font-size: $font-14;
        // padding-left: 1rem;
      }
      &--success {
        color: $success;
        font-size: $font-14;
        // padding-left: 1rem;
      }
      &--danger {
        color: $danger;
        font-size: $font-14;
        // padding-left: 1rem;
      }
    }

    &-floating {
      position: relative;
      & > label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 1rem 0.75rem;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        font-weight: 700;
        color: $gray-500;
        user-select: none;
      }
      & > .l-form-field {
        height: calc(3.5rem + 2px);
        padding: 1rem 0.75rem;
        &::placeholder {
          color: transparent;
        }
        &:not(:placeholder-shown),
        &:focus {
          padding-top: 1.625rem;
          padding-bottom: 0.65rem;
          & + label {
            transform: scale(0.8) translateY(-0.5rem) translateX(0.25rem);
            opacity: 0.7;
          }
        }
      }
    }
    &-check {
      &-input {
        display: none;
        &[type="checkbox"] {
          &:checked {
            & + .l-form-check-label:before {
              background-color: $black;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            }
          }
          &:disabled {
            & + .l-form-check-label {
              opacity: 0.3;
            }
          }
          &.js-valid {
            & + .l-form-check-label {
              color: $success;
              &:before {
                border-color: $success;
              }
            }
            &:checked {
              & + .l-form-check-label {
                &:before {
                  background-color: $success;
                }
              }
            }
          }
          &.js-invalid {
            & + .l-form-check-label {
              color: $danger;
              &:before {
                border-color: $danger;
              }
            }
            &:checked {
              & + .l-form-check-label {
                &:before {
                  background-color: $danger;
                }
              }
            }
          }
        }
        &[type="radio"] {
          & + .l-form-check-label:before {
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          &:checked {
            & + .l-form-check-label:before {
              background-color: $black;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
            }
          }
          &:disabled {
            & + .l-form-check-label {
              opacity: 0.3;
            }
          }
          &.js-valid {
            & + .l-form-check-label {
              color: $success;
              &:before {
                border-color: $success;
              }
            }
            &:checked {
              & + .l-form-check-label {
                &:before {
                  background-color: $success;
                }
              }
            }
          }
          &.js-invalid {
            & + .l-form-check-label {
              color: $danger;
              &:before {
                border-color: $danger;
              }
            }
            &:checked {
              & + .l-form-check-label {
                &:before {
                  background-color: $danger;
                }
              }
            }
          }
        }
      }
      &-label {
        position: relative;
        padding-left: 1.2rem;
        margin-bottom: 0;
        &:before {
          content: "";
          display: inline-block;
          width: 1rem;
          height: 1rem;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: $white;
          transition: background-color 0.15s ease-in-out,
            background-position 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          border: 1px solid rgba($black, 0.25);
        }
      }
    }
    &-switch {
      &-input {
        display: none;
        &[type="checkbox"] {
          &:checked {
            & + .l-form-switch-label:before {
              background-color: $black;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
              background-position: right center;
            }
          }
          &:disabled {
            & + .l-form-switch-label {
              opacity: 0.3;
            }
          }
          &.js-valid {
            & + .l-form-switch-label {
              color: $success;
              &:before {
                border-color: $success;
              }
            }
            &:checked {
              & + .l-form-switch-label {
                &:before {
                  background-color: $success;
                }
              }
            }
          }
          &.js-invalid {
            & + .l-form-switch-label {
              color: $danger;
              &:before {
                border-color: $danger;
              }
            }
            &:checked {
              & + .l-form-switch-label {
                &:before {
                  background-color: $danger;
                }
              }
            }
          }
        }
      }
      &-label {
        position: relative;
        padding-left: 2.2rem;
        margin-bottom: 0;
        &:before {
          content: "";
          display: inline-block;
          width: 1.8rem;
          height: 1rem;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: $white;
          transition: background-color 0.15s ease-in-out,
            background-position 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          border: 1px solid rgba($black, 0.25);
          border-radius: 0.5rem;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
          background-position: left center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

textarea.l-form-field {
  min-height: 255px;
  height: auto;
  resize: none;
  &.js-valid {
    background-position: top calc(0.375em + 0.1875rem) right
      calc(0.375em + 0.1875rem);
  }
  &.js-invalid {
    background-position: top calc(0.375em + 0.1875rem) right
      calc(0.375em + 0.1875rem);
  }
}
