.l-header {
  & > .container {
    min-height: 100vh;
    position: fixed;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
  &-hamburger {
    width: 30px;
    height: 18px;
    position: absolute;
    right: 15px;
    @include min-md {
      right: 0;
    }
    top: 50%;
    transform: translateY(-50%);
    pointer-events: auto;
    &-bar {
      width: 100%;
      height: 2px;
      background-color: $black;
      position: absolute;
      transition: top 0.3s ease-out, transform 0.3s ease-out,
        opacity 0.3s ease-out, background-color 0.3s ease-out;
      &__top {
        top: 0;
        left: 0;
      }
      &__middle {
        left: 0;
        top: 50%;
      }
      &__bottom {
        left: 0;
        top: 100%;
      }
    }
    &.js-menuOpened {
      .l-header-hamburger-bar {
        &__top {
          background-color: #fff;
          top: 50%;
          transform: rotate(45deg);
        }
        &__middle {
          opacity: 0;
        }
        &__bottom {
          background-color: #fff;
          top: 50%;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &-menu {
    position: fixed;
    z-index: 700;
    min-height: 100vh;
    width: 100%;
    background: $main;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    &.js-menuOpened {
      transform: translateX(0);
    }
    &:before {
      content: "";
      background: url("../img/logo3.svg") no-repeat center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 100vh;
    }
    .container {
      display: flex;
      align-items: center;
      min-height: 100vh;
    }
    a {
      color: $white;
      font-size: 24px;
      @include min-md {
        font-size: 64px;
      }
      font-weight: 700;
    }
    span {
      position: absolute;
      left: 15px;
      bottom: 30px;
      color: $white;
      @include min-md {
        left: 0;
      }
    }
  }
}
