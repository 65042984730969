//page .p-{className}
.logo {
  display: inline-block;
  width: 200px;
  padding: 58px 0;
  position: absolute;
  top: 0;
  left: 0;
  @include max-sm {
    width: 150px;
    margin: 0 15px;
  }
}

#kv {
  position: relative;
  height: 100vh;
  z-index: 20;
  span {
    position: absolute;
    left: 50%;
    bottom: 58px;
    transform: translateX(-50%);
    user-select: none;
  }
  p {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.25em;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    @include max-sm {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

#about {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 5rem 0;
  p,
  h3 {
    position: relative;
    z-index: 50;
  }
}

#service {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 5rem 0;
  background-color: $main;
  p,
  h3 {
    position: relative;
    z-index: 50;
  }
}

#clients {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  img {
    width: 100%;
    display: block;
    filter: grayscale(100%);
    max-width: 160px;
  }
}

#recruit {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 5rem 0;
  background-color: $main;
}

#contact {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 5rem 0;
}

#slider {
  width: 100%;
  height: 100vh;
  div {
    display: none;
  }
  position: absolute;
  transition: transform 1s ease-in-out;
}

.p- {
  &slide {
    &-place-holder {
      min-height: 350px;
    }
  }
}
