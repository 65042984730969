// 針對 html 標籤做基本設定
ul {
  list-style: none;
  padding-left: 0;
}
button {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);
  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }
}
a {
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);
  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }
  &:hover {
    text-decoration: none;
  }
}

h3 {
  font-size: 36px;
  @include min-md {
    font-size: 60px;
  }
  @include min-lg {
    font-size: 72px;
  }
  @include min-xl {
    font-size: 96px;
  }
}
