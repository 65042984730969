//layout .l-{className}
.l- {
  &footer {
    padding: 30px 15px;
    background-color: $main;
    color: $white;
    display: flex;
    flex-direction: column;
    a {
      color: $white;
    }
    @include min-sm {
      align-items: center;
      padding: 30px 0;
    }
  }
}
