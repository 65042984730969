.c- {
  &btn {
    display: inline-block;
    color: $black;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &--shadow {
      box-shadow: 3px 3px 5px rgba($gray-400, 20%);
    }
    &--rounded {
      border-radius: 50px;
    }
    &--sharp {
      border-radius: 0;
    }
    &--block {
      display: block;
      width: 100%;
    }
  }
}

@each $color, $value in $button-color-theme {
  .c-btn--contained {
    &.c-btn-#{$color} {
      background-color: $value;
      color: $white;
      &:hover,
      &.js-btnHover {
        background-color: darken($value, 10%);
        color: $white;
      }
      &:focus,
      &.js-btnFocus {
        background-color: darken($value, 10%);
        color: $white;
        outline: none;
        box-shadow: 0 0 0 4px rgba($value, 0.5);
      }
      &:active,
      &.js-btnActive {
        background-color: darken($value, 20%);
        color: $white;
      }
      &:disabled,
      &.js-btnDisabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .c-btn--outlined {
    &.c-btn-#{$color} {
      color: $value;
      border-color: $gray-500;
      &:hover,
      &.js-btnHover {
        border-color: $value;
        background-color: $value;
        color: $white;
      }
      &:focus,
      &.js-btnFocus {
        border-color: $value;
        background-color: $value;
        color: $white;
        outline: 0;
        box-shadow: 0 0 0 4px rgba($value, 0.5);
      }
      &:active,
      &.js-btnActive {
        border-color: darken($value, 10%);
        background-color: darken($value, 10%);
        color: $white;
      }
      &:disabled,
      &.js-btnDisabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}

button[type="submit"] {
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:hover {
    color: $main;
    &:before,
    &:after {
      border-top: 1px solid $main;
    }
  }
  &:before {
    content: "";
    display: block;
    border-top: 1px solid $gray-800;
    width: 68px;
    position: absolute;
    top: 50%;
    left: -16px;
    transform: translate(-100%, -50%);
  }
  &:after {
    content: "";
    display: block;
    border-top: 1px solid $gray-800;
    width: 68px;
    position: absolute;
    top: 50%;
    right: -16px;
    transform: translate(100%, -50%);
  }
}
